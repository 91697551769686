import React from 'react';
import { ButtonPriority, Divider } from 'wix-ui-tpa';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { groupBy } from 'lodash';
import { ApiTypesV1GroupResponse, GroupWrapper } from '@wix/social-groups-api';
import { CreateGroupButton } from '../Groups/CreateGroupButton';
import { Box } from '../../../../common/components/Box/Box';
import { Search } from '../../../../common/components/Search';
import { NoGroups } from '../../../../common/components/GroupList/NoGroups';
import { useGroupsActions } from '../../contexts/useGroupsActions';
import { Groups } from './Groups/Groups';
import { st, classes } from './SideBar.st.css';
import { useSettings } from '@wix/tpa-settings/react';
import { settingsParams } from '../../../../common/settings/groups-list/settings';
import { useUser } from '../../../../common/context/user/useUser';

type SideBarProps = {
  onCreateButtonClick(): void;
  groups: ApiTypesV1GroupResponse[];
};

export const SideBar: React.FC<SideBarProps> = (props: SideBarProps) => {
  const rootEl = React.useRef<HTMLElement>(null);
  // to keep the height the same between different search result
  const [height, setHeight] = React.useState('min-content');

  const { t } = useTranslation();
  const actions = useGroupsActions();
  const { userPermissions } = useUser();

  const settings = useSettings();
  const shouldShowSearch = settings.get(settingsParams.showSearchSorting);
  const shouldShowCreateGroupButton =
    settings.get(settingsParams.showCreateGroupButton) &&
    userPermissions.canCreateGroup;
  const shouldShowGroupsToJoin = settings.get(settingsParams.showGroupsToJoin);

  React.useEffect(() => {
    if (rootEl && rootEl.current && height === 'min-content') {
      setHeight(`${rootEl.current.offsetHeight}px`);
    }
  }, [rootEl.current]);

  const groupsByMembership = React.useMemo(() => {
    return groupBy(props.groups, function isJoined(group) {
      const g = new GroupWrapper(group);
      if (g.isJoined()) {
        return 'my';
      }
      return 'toJoin';
    });
  }, [props.groups]);

  const hasMyGroups = groupsByMembership.my && groupsByMembership.my.length;
  const hasGroupsToJoin =
    groupsByMembership.toJoin &&
    groupsByMembership.toJoin.length &&
    shouldShowGroupsToJoin;
  const hasGroups = hasMyGroups || hasGroupsToJoin;

  return (
    <Box
      className={st(classes.root, { withSearch: shouldShowSearch })}
      ref={rootEl}
      style={{ height }}
    >
      {shouldShowSearch ? (
        <Search
          withBorder={true}
          forceOpen={true}
          withCloseButton={true}
          placeholder={t('groups-web.search.placeholder')}
          onChange={actions.searchGroups}
          className={classes.searchBox}
        />
      ) : null}
      <div className={st(classes.groups, { empty: !hasGroups })}>
        {hasGroups ? renderGroups() : renderNoSearchResults()}
      </div>
      {shouldShowCreateGroupButton ? (
        <div className={classes.createGroupButtonWrapper}>
          <CreateGroupButton
            biOrigin="side-bar"
            priority={ButtonPriority.secondary}
            className={classes.createGroupButton}
            onClick={props.onCreateButtonClick}
          >
            {t('groups-web.btn.create-new')}
          </CreateGroupButton>
        </div>
      ) : null}
    </Box>
  );

  function renderGroups() {
    return (
      <>
        {hasMyGroups ? (
          <Groups
            goToGroup={actions ? actions.goToGroup : undefined}
            groups={groupsByMembership.my}
            title={t('groups-web.side-bar.my-groups')}
          />
        ) : null}
        {hasMyGroups && hasGroupsToJoin ? (
          <Divider className={classes.divider} />
        ) : null}
        {hasGroupsToJoin ? (
          <Groups
            goToGroup={actions ? actions.goToGroup : undefined}
            groups={groupsByMembership.toJoin}
            title={t('groups-web.side-bar.groups-to-join')}
          />
        ) : null}
      </>
    );
  }

  function renderNoSearchResults() {
    return (
      <NoGroups
        className={classes.noGroups}
        emptyStateHeader={t('groups-web.search.no-results.title')}
        emptyStateText={t('groups-web.search.no-results.text')}
      />
    );
  }
};
