import React from 'react';
import { ThemeProvider } from 'common/context/theme';
import { settingsParams } from 'common/settings/groups-list/settings';
import { stylesParams } from 'common/settings/groups-list/styles';
import { classes, st } from 'Groups/Widget/Groups/Groups.st.css';
import {
  GroupsSection,
  GroupsSectionRenderProps,
} from 'Groups/Widget/Groups/GroupsSection';
import { ListLayout } from 'Groups/Widget/Groups/ListLayout/ListLayout';
import { useSettings, useStyles } from '@wix/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useGroupsSettings } from 'common/hooks/settings/useGroupsSettings';
import { LayoutType } from 'common/settings/groups-list/settingsConstants';
import { SideBarLayout } from 'Groups/Widget/Groups/SideBarLayout/SideBarLayout';

export const Groups: React.FC = () => {
  const settings = useSettings();
  const styles = useStyles();
  const groupsSettings = useGroupsSettings();
  const { isMobile } = useEnvironment();

  return (
    <ThemeProvider
      value={{
        buttonType: settings.get(settingsParams.buttonType),
        buttonBorderRadius: styles.get(stylesParams.buttonBorderRadius),
      }}
    >
      <div
        data-hook="groups"
        className={st(classes.root, { mobile: isMobile })}
      >
        <GroupsSection>{renderLayout}</GroupsSection>
      </div>
    </ThemeProvider>
  );

  function renderLayout({ openCreateGroupModal }: GroupsSectionRenderProps) {
    switch (groupsSettings.groupListLayout) {
      case LayoutType.sideBar:
        return <SideBarLayout onCreateGroupClick={openCreateGroupModal} />;
      default:
        return <ListLayout onCreateGroupClick={openCreateGroupModal} />;
    }
  }
};
